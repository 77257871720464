import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import downloadFile from '../../images/5 sposobów na szybszy awans.pdf' 

const LM = ({data, location}) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <h1>Poradnik - 5 sposobów na szybszy awans</h1>
      <h4><a class="decorated" href={downloadFile} download> POBIERZ TUTAJ </a>{` `}</h4>
    </Layout>
  )
}

export default LM

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`